* {
  box-sizing: border-box;
}
p {
  font-size: 24px;
  color: #151515;
  line-height: 1.5;
  font-weight: 400;
}

h1 {
  text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  font-size: 40;
}

.nav-btns {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 16px;
}
.social-icons {
  cursor: pointer;
}
.boxed-span {
  background-color: white;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
}
